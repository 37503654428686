import React, { useEffect, useState } from "react";
import DeepLinks from "@lib/capacitorPlugins/DeepLinks";
import { Modal } from "antd";
import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import Button from "@components/UI/Button/Button";
import { useTranslation } from "react-i18next";
import LinkIcon from "@components/UI/Icons/LinkIcon";

const AndroidCheckDeepLinks: React.FC<{ showButton?: boolean }> = ({
  showButton = false,
}) => {
  const { t } = useTranslation("common");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    openSettings();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    (async () => {
      const { value } = await Preferences.get({
        key: "utilsAndroidDeepLinksMessageShown",
      });

      if (Capacitor.getPlatform() === "android" && !value) {
        showModal();
        await Preferences.set({
          key: "utilsAndroidDeepLinksMessageShown",
          value: "true",
        });
      }
    })();
  }, []);

  const openSettings = async () => {
    await DeepLinks.open({});
  };

  return (
    <>
      {showButton && Capacitor.getPlatform() === "android" ? (
        <Button
          icon={
            <LinkIcon style={{ width: 16, height: 16, verticalAlign: "top" }} />
          }
          onClick={showModal}
        >
          {t("DeepLinksButton")}
        </Button>
      ) : null}
      <Modal
        title={t("DeepLinksTitle")}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={t("Yes")}
        cancelText={t("Cancel")}
      >
        <p>{t("DeepLinksInfo")}</p>
        <p>{t("DeepLinksOpenAppSettings")}</p>
      </Modal>
    </>
  );
};

export default AndroidCheckDeepLinks;
