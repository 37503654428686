import { theme } from "antd";
import { useTranslation } from "react-i18next";
import AuthBlock from "../AuthBlock/AuthBlock";
import { gql, useApolloClient, useQuery } from "@apollo/client";
import { UsersOnAchievements } from "@prisma/client";
import { Fragment, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useSession } from "@lib/auth";
import { useCurrentPath } from "@components/App/App";
import styles from "./nav.module.scss";
import { useBoolean, useResponsive } from "ahooks";
import {
  MyQuestsQuery,
  MySettingsQuery,
  useApp,
} from "@components/App/AppProvider";
import Spacer from "@components/Spacer/Spacer";
import FocusIcon from "@components/FocusIcon";
import PresentationChartLineIcon from "@components/UI/Icons/PresentationChartLineIcon";
import CheckCircleIcon from "@components/UI/Icons/CheckCircleIcon";
import FlagIcon from "@components/UI/Icons/FlagIcon";
import ClockIcon from "@components/UI/Icons/ClockIcon";
import UserIcon from "@components/UI/Icons/UserIcon";
import MagnifyingGlassIcon from "@components/UI/Icons/MagnifyingGlassIcon";
import Search from "@components/Search/Search";

export const Logo = ({ width = 80, height = 80 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M37.1539 1.43806C38.5669 -0.479353 41.4331 -0.479353 42.8461 1.43806L57.6814 21.5699C57.8919 21.8556 58.1444 22.1081 58.4301 22.3187L78.5619 37.1539C80.4794 38.5669 80.4794 41.4331 78.562 42.8461L58.4301 57.6814C58.1444 57.8919 57.8919 58.1444 57.6814 58.4301L42.8461 78.5619C41.4331 80.4794 38.5669 80.4794 37.1539 78.562L22.3187 58.4301C22.1081 58.1444 21.8556 57.8919 21.5699 57.6814L1.43806 42.8461C-0.479353 41.4331 -0.479353 38.5669 1.43806 37.1539L21.5699 22.3187C21.8556 22.1081 22.1081 21.8556 22.3187 21.5699L37.1539 1.43806Z"
      fill="url(#paint0_linear_301_123)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_301_123"
        x1="40"
        y1="-2.42407"
        x2="40"
        y2="82.4241"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC700" />
        <stop offset="1" stopColor="#FF8A00" />
      </linearGradient>
    </defs>
  </svg>
);

const NewAchievementsQuery = gql`
  query {
    userAchievements {
      received
      viewed
    }
  }
`;

function Nav({ finishQuestButton }: { finishQuestButton?: React.ReactNode }) {
  const { subscriptionActive } = useApp();
  const { t } = useTranslation("common");
  const { data: session, status } = useSession();
  const {
    token: { colorBgContainer, colorTextBase },
  } = theme.useToken();
  const client = useApolloClient();
  const currentRoute = useCurrentPath();

  const { data } = useQuery<{
    userAchievements: UsersOnAchievements[];
  }>(NewAchievementsQuery, {
    fetchPolicy: "no-cache",
  });
  const [
    searchOpen,
    { setTrue: handleSearchOpen, setFalse: handleSearchClose },
  ] = useBoolean(false);

  const newAchievementsCount = useMemo(
    () =>
      data && data.userAchievements
        ? data.userAchievements.reduce(
            (prev, current) =>
              !current.viewed && current.received ? prev + 1 : prev,
            0
          )
        : 0,
    [data]
  );

  useEffect(() => {
    client.refetchQueries({
      include: [MySettingsQuery, MyQuestsQuery, NewAchievementsQuery],
    });
  }, [currentRoute, client]);

  const desktopNav = useMemo(
    () =>
      !!session && status === "ready"
        ? [
            session &&
            session.user &&
            session.user.email === "support@forgeself.com"
              ? {
                  to: "/dash",
                  label: "Dash",
                  icon: <PresentationChartLineIcon width={20} height={20} />,
                }
              : null,
            {
              to: "/dashboard",
              label: t("Main"),
              icon: <CheckCircleIcon width={20} height={20} variant="solid" />,
            },
            {
              to: "/goals",
              label: t("Goals"),
              icon: <FlagIcon width={20} height={20} variant="solid" />,
            },
            {
              to: "/timetracker",
              label: t("Time tracker"),
              icon: <ClockIcon width={20} height={20} variant="solid" />,
            },
            subscriptionActive
              ? {
                  to: "/focusing",
                  label: t("Focusing"),
                  icon: <FocusIcon width={20} height={20} variant="solid" />,
                }
              : null,
            {
              to: "/profile",
              label: t("Profile"),
              icon: <UserIcon width={20} height={20} variant="solid" />,
            },
          ].filter((item) => item !== null)
        : [],
    [session, status, t, subscriptionActive]
  );

  const tabs = useMemo(
    () =>
      !!session && status === "ready"
        ? [
            {
              to: "/dashboard",
              label: t("Main"),
              icon: (
                <CheckCircleIcon
                  width={20}
                  height={20}
                  style={{ verticalAlign: "bottom" }}
                  variant="solid"
                />
              ),
            },
            {
              to: "/goals",
              label: t("Goals"),
              icon: (
                <FlagIcon
                  width={20}
                  height={20}
                  style={{ verticalAlign: "bottom" }}
                  variant="solid"
                />
              ),
            },
            {
              to: "/timetracker",
              label: t("Time tracker"),
              icon: (
                <ClockIcon
                  width={20}
                  height={20}
                  style={{ verticalAlign: "bottom" }}
                  variant="solid"
                />
              ),
            },
            /* subscriptionActive
              ? {
                  to: "/focusing",
                  label: t("Focusing"),
                  icon: <FocusIcon width={20} height={20} />,
                }
              : null, */
            {
              label: t("Search"),
              icon: (
                <MagnifyingGlassIcon
                  width={20}
                  height={20}
                  style={{ verticalAlign: "bottom" }}
                />
              ),
              onClick: handleSearchOpen,
            },
            {
              to: "/profile",
              label: t("Profile"),
              icon: (
                <UserIcon
                  width={20}
                  height={20}
                  style={{ verticalAlign: "bottom" }}
                  variant="solid"
                />
              ),
            },
          ].filter((item) => item !== null)
        : [],
    [session, status, subscriptionActive, t, handleSearchOpen]
  );

  const responsive = useResponsive();
  const isMobile = useMemo(
    () => !responsive.md && !responsive.lg && !responsive.xl,
    [responsive]
  );

  return isMobile ? (
    <>
      {!!session && status === "ready" ? (
        <div className={styles.mobnav}>
          {tabs.map((item, idx) =>
            item ? (
              <Fragment key={item.label}>
                {item.to ? (
                  <Link
                    to={item.to}
                    className={classNames(styles.tab, {
                      [styles.tabActive]: currentRoute === item.to,
                    })}
                    draggable={false}
                  >
                    <div className={styles.tabIcon}>{item.icon}</div>
                    <div className={styles.tabLabel}>{item.label}</div>
                  </Link>
                ) : (
                  <div
                    className={styles.tab}
                    draggable={false}
                    tabIndex={0}
                    onClick={item.onClick}
                    style={{ cursor: "pointer" }}
                  >
                    <div className={styles.tabIcon}>{item.icon}</div>
                    <div className={styles.tabLabel}>{item.label}</div>
                  </div>
                )}
              </Fragment>
            ) : null
          )}
        </div>
      ) : (
        <>
          {currentRoute !== "/" ? (
            <div className={styles.mobnavNotLoggedIn}>
              <Link
                to="/"
                className="logo"
                style={{ position: "static", margin: 0 }}
                draggable={false}
              >
                <Logo width={24} height={24} />
                <div className="logo__title">ForgeSelf</div>
              </Link>
              {currentRoute !== "/auth/signin" && <AuthBlock />}
            </div>
          ) : null}
        </>
      )}
      <Search open={searchOpen} onClose={handleSearchClose} />
      {finishQuestButton}
    </>
  ) : (
    <>
      {!!session && status === "ready" ? (
        <div
          className={`${styles.sidebar} fs-nav-sidebar`}
          style={{ background: colorBgContainer }}
        >
          <div>
            <AuthBlock desktop />
            <div
              className={styles.sidebarLink}
              draggable={false}
              tabIndex={0}
              onClick={handleSearchOpen}
            >
              <div className={styles.sidebarLinkIcon}>
                <MagnifyingGlassIcon width={20} height={20} />
              </div>
              <div className={styles.sidebarLinkText}>{t("Search")}</div>
            </div>
            <Spacer y={1} />
            {desktopNav.map((item) =>
              item ? (
                <Link
                  key={item.to}
                  to={item.to}
                  className={classNames(styles.sidebarLink, {
                    [styles.sidebarLinkActive]: currentRoute === item.to,
                  })}
                  draggable={false}
                >
                  <div className={styles.sidebarLinkIcon}>{item.icon}</div>
                  <div className={styles.sidebarLinkText}>{item.label}</div>
                </Link>
              ) : null
            )}
            {finishQuestButton ? (
              <>
                <Spacer y={2} />
                {finishQuestButton}
              </>
            ) : null}
          </div>
          {/* <Spacer y={2} />
          <SupportBtn variant="premium" /> */}
        </div>
      ) : null}
      <Search open={searchOpen} onClose={handleSearchClose} />
    </>
  );
}

export default Nav;
