import React, { ReactNode } from "react";
import styles from "./Label.module.scss";

export interface Props {
  children: ReactNode;
  style?: React.CSSProperties;
}

const Label = React.forwardRef<HTMLLabelElement, Props>(
  ({ children, style, ...rest }, ref) => {
    return (
      <label className={styles.label} ref={ref} style={style} {...rest}>
        {children}
      </label>
    );
  }
);

export default Label;
