import { Avatar, Popover } from "antd";
import { forwardRef, useEffect, useState } from "react";
import { useSession, authenticationService } from "../../lib/auth";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "@components/UI/Button/Button";
import { gql, useQuery } from "@apollo/client";
import { Media, Prisma } from "@prisma/client";
import styles from "./AuthBlock.module.scss";
import LinkIcon from "@components/UI/Icons/LinkIcon";
import { Capacitor } from "@capacitor/core";

export const MiniProfileQuery = gql`
  query MiniProfile {
    user {
      name
      mediaItems {
        id
        mediaRole
        downloadUrl
      }
    }
  }
`;

// The approach used in this component shows how to built a sign in and sign out
// component that works on pages which support both client and server side
// rendering, and avoids any flash incorrect content on initial page load.
function AuthBlock({ desktop = false }: { desktop?: boolean }) {
  const { t } = useTranslation("common");
  const { data: session, status } = useSession();
  const loading = status === "loading";

  if (!session)
    return Capacitor.getPlatform() !== "web" ? (
      <Link to="/auth/signin">
        <Button style={{ width: 120, fontSize: '1.15em' }} v2 size="large" color="primary">{t("Login")}</Button>
      </Link>
    ) : (
      <Link className={styles.signin} to="/auth/signin">
        <LinkIcon width={20} height={20} />
        <span>{t("web-version")}</span>
      </Link>
    );

  return (
    <>
      <noscript>
        <style>{`.nojs-show { opacity: 1; top: 0; }`}</style>
      </noscript>
      <Popover
        placement="bottom"
        content={
          <div className={styles.signedInStatus}>
            <div
              className={`nojs-show ${
                !session && loading ? styles.loading : styles.loaded
              }`}
            >
              {session?.user && (
                <>
                  <span className={styles.signedInText}>
                    <small>{t("Your email")}</small>
                    <br />
                    <strong>{session.user.email || session.user.name}</strong>
                  </span>
                  {/* <a
                    href={`/api/auth/signout`}
                    onClick={(e) => {
                      e.preventDefault();
                      authenticationService.signOut();
                    }}
                  > </a> */}
                  <Button
                    style={{ marginTop: 10 }}
                    onClick={(e) => {
                      e.preventDefault();
                      authenticationService.signOut();
                    }}
                  >
                    {t("Log out")}
                  </Button>
                </>
              )}
            </div>
          </div>
        }
        trigger="click"
      >
        {desktop ? (
          <div style={{ cursor: "pointer", marginBottom: 16 }}>
            <MiniProfile />
          </div>
        ) : (
          <Avatar
            icon={session?.user ? "USR" : "..."}
            style={{
              verticalAlign: "top",
              cursor: "pointer",
            }}
          />
        )}
      </Popover>
    </>
  );
}

type UserWithMedia = Prisma.UserGetPayload<{
  include: { mediaItems: true };
}>;

const MiniProfile = forwardRef<HTMLDivElement>((props, ref) => {
  const { data } = useQuery<{
    user: UserWithMedia;
  }>(MiniProfileQuery, {
    fetchPolicy: "network-only",
  });
  const [userImageUrl, setUserImageUrl] = useState<string>();
  useEffect(() => {
    const mediaList = data?.user?.mediaItems ?? [];
    const url =
      mediaList.length > 0
        ? (mediaList[0] as Media & { downloadUrl: string }).downloadUrl
        : undefined;
    setTimeout(() => {
      setUserImageUrl(url);
    }, 500);
  }, [data]);
  return (
    <div
      ref={ref}
      style={{ display: "flex", padding: 4, alignItems: "center" }}
    >
      <div
        className="profileMedia"
        style={{ flexShrink: 0, width: 32, height: 32, marginRight: 8 }}
      >
        {userImageUrl ? (
          <img
            src={userImageUrl}
            style={{ width: 32, height: 32, objectFit: "cover" }}
            alt="Profile"
          />
        ) : null}
      </div>
      <div className={styles.username}>{data?.user?.name ?? "nickname"}</div>
    </div>
  );
});

export default AuthBlock;
