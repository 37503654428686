import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  matchRoutes,
  Link,
} from "react-router-dom";
import {
  Layout as AntLayout,
  App as AntdApp,
  ConfigProvider,
  theme as AntdTheme,
} from "antd";
import { useTranslation } from "react-i18next";
import { useBoolean, useResponsive } from "ahooks";
import useMediaQuery from "@lib/hooks/useMediaQuery";
import React, {
  FC,
  Suspense,
  lazy,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { Preferences } from "@capacitor/preferences";
import { ConnectionStatus, Network } from "@capacitor/network";
import { CapacitorUpdater } from "@capgo/capacitor-updater";
import HomePage from "../../pages/HomePage";
import NotFoundPage from "../../pages/NotFoundPage";
import { useSession } from "../../lib/auth";
import { Helmet } from "react-helmet-async";
import styles from "./App.module.scss";
import AppProvider, { useApp } from "./AppProvider";
import Spacer from "@components/Spacer/Spacer";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import "dayjs/locale/en";
import ruRU from "antd/locale/ru_RU";
import enUS from "antd/locale/en_US";
import AppUrlListener from "./AppUrlListener";
import AndroidCheckDeepLinks from "./AndroidCheckDeepLinks";
import FocusIcon from "@components/FocusIcon";

if (Capacitor.getPlatform() !== "web") {
  CapacitorUpdater.notifyAppReady();
}

const DashboardPage = lazy(() => import("@pages/DashboardPage"));
const TodoPage = lazy(() => import("@pages/TodoPage"));
const GoalPage = lazy(() => import("@pages/GoalPage"));
const ProfilePage = lazy(() => import("@pages/ProfilePage/ProfilePage"));
const FirstQuest = lazy(() => import("@components/FirstQuest/FirstQuest"));
const DashPage = lazy(() => import("@pages/DashPage"));
const FocusingPage = lazy(() => import("@pages/FocusingPage"));
const TrackedTimePage = lazy(() => import("@pages/TrackedTimePage"));
const SubscriptionPage = lazy(
  () => import("@pages/SubscriptionPage/SubscriptionPage")
);
const AchievementsPage = lazy(() => import("@pages/AchievementsPage"));
const GoalsPage = lazy(() => import("@pages/GoalsPage"));
const AuthSignInPage = lazy(() => import("@pages/AuthSignInPage"));

const PolicyPage = lazy(() => import("@pages/docs/PolicyPage"));
const SupportPage = lazy(() => import("@pages/docs/SupportPage"));
const PricingPage = lazy(() => import("@pages/docs/PricingPage"));
const GoalsProblemPage = lazy(() => import("@pages/docs/GoalsProblemPage"));
const PlanningProblemPage = lazy(
  () => import("@pages/docs/PlanningProblemPage")
);
const FlexibilitySolvesPage = lazy(
  () => import("@pages/docs/FlexibilitySolvesPage")
);
const PositiveMotivationPage = lazy(
  () => import("@pages/docs/PositiveMotivationPage")
);
const PersonalizationPage = lazy(
  () => import("@pages/docs/PersonalizationPage")
);
const TimeSavingPage = lazy(() => import("@pages/docs/TimeSavingPage"));
const GoalTrackerPage = lazy(() => import("@pages/docs/GoalTrackerPage"));
const DailyPlannerPage = lazy(() => import("@pages/docs/DailyPlannerPage"));
const TimeTrackerPage = lazy(() => import("@pages/docs/TimeTrackerPage"));

const routes = [
  { path: "/" },
  { path: "/dashboard" },
  { path: "/achievements" },
  { path: "/goals" },
  { path: "/goal/:id" },
  { path: "/todo/:id" },
  { path: "/timetracker" },
  { path: "/focusing" },
  { path: "/premium" },
  { path: "/profile" },
  { path: "/policy" },
  { path: "/auth/signin" },
  { path: "/dash" },
  { path: "/pricing" },
  { path: "/support" },
  // docs
  {
    path: "/goals-achieving-problem",
  },
  {
    path: "/planning-things-problem",
  },
  {
    path: "/flexibility-solves",
  },
  {
    path: "/positive-motivation",
  },
  {
    path: "/personalization",
  },
  {
    path: "/time-saving",
  },
  {
    path: "/use-cases/goal-tracker",
  },
  {
    path: "/use-cases/daily-planner",
  },
  {
    path: "/use-cases/time-tracker",
  },
];

export const useCurrentPath = () => {
  const location = useLocation();
  const routeMatched = matchRoutes(routes, location)!;

  return routeMatched ? routeMatched[0].route.path : null;
};

function RequireAuth({ children }: { children: JSX.Element }) {
  const { data: session, status } = useSession();
  let location = useLocation();
  if (status === "loading") return null;
  if (!session) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/auth/signin" state={{ from: location }} replace />;
  }

  return children;
}

function RequireSubscription({
  children,
  info,
}: {
  children: JSX.Element;
  info: React.ReactNode;
}) {
  const { subscriptionActive } = useApp();

  if (!subscriptionActive) {
    return <>{info}</>;
  }

  return children;
}

const OFFLINE_KEY = "OFFLINE_KEY";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // исправить баг скролла – если переключить страницу во время
    // плавной анимации скролла, скролтоп не сработает и содержимое криво уедет (((
    // сделать overflow: hidden у боди и всего остального - разрешить скролл только внутри контента page
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const { i18n } = useTranslation("common");
  // const size = useSize(document.querySelector("#root"));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const { value: settingsLanguage } = await Preferences.get({
        key: "settingsLanguage",
      });
      if (!settingsLanguage) {
        const { value: lng } = await Device.getLanguageCode();
        const newLng = lng === "ru" ? "ru" : "en";
        i18n.changeLanguage(newLng);
        await Preferences.set({
          key: "settingsLanguage",
          value: newLng,
        });
        dayjs.locale(newLng);
      } else {
        i18n.changeLanguage(settingsLanguage);
        dayjs.locale(settingsLanguage);
      }
      setLoading(false);
    })();
  }, []);

  return loading ? null : (
    <Router>
      <AppProvider>
        <AppContent />
      </AppProvider>
    </Router>
  );
}

function AppContent() {
  const { theme } = useApp();
  const { i18n } = useTranslation("common");
  const responsive = useResponsive();
  const isMobile = useMemo(
    () => !responsive.md && !responsive.lg && !responsive.xl,
    [responsive]
  );
  const isDarkScheme = useMediaQuery("(prefers-color-scheme: dark)");

  useEffect(() => {
    if ((isDarkScheme && theme === null) || theme === "dark") {
      document.body.classList.add("fs-dark");
    }
    if ((!isDarkScheme && theme === null) || theme === "light") {
      document.body.classList.remove("fs-dark");
    }
  }, [isDarkScheme, theme]);

  return (
    <ConfigProvider
      theme={{
        algorithm:
          (isDarkScheme && theme === null) || theme === "dark"
            ? AntdTheme.darkAlgorithm
            : AntdTheme.defaultAlgorithm,
        token: {
          // borderRadius: 2,
          colorPrimary: "#3185FC",
          fontSize: 16,
        },
      }}
      locale={i18n.language === "en" ? enUS : ruRU}
    >
      <AntdApp>
        <NetworkStatusNotify />
        <AppUrlListener />
        <AndroidCheckDeepLinks />
        <Helmet>
          {Capacitor.isNativePlatform() ? (
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no,viewport-fit=cover"
            />
          ) : (
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
          )}
        </Helmet>
        <ScrollToTop />
        <AntLayout
          style={
            isMobile
              ? {
                  height: "100%", // size?.height ?? "100vh",
                  // overflow: "hidden",
                  flexDirection: "column-reverse",
                  background: "transparent",
                }
              : {
                  height: "100%",
                  background: "transparent",
                  flexDirection: "row",
                  // minHeight: "100vh"
                }
          }
          className={styles.app}
        >
          <Intro />
        </AntLayout>
      </AntdApp>
    </ConfigProvider>
  );
}

function NetworkStatusNotify() {
  const { message } = AntdApp.useApp();
  const { t } = useTranslation("common");
  useEffect(() => {
    Network.addListener("networkStatusChange", (status: ConnectionStatus) => {
      if (status.connected) {
        message.destroy(OFFLINE_KEY);
      } else {
        message.warning({
          key: OFFLINE_KEY,
          content: t("OfflineMessage"),
          duration: 0,
        });
      }
    });
    return () => {
      Network.removeAllListeners();
    };
  }, []);
  return null;
}

const Intro: FC = () => {
  const { t } = useTranslation("common");
  const [
    questOpen,
    { toggle, set, setTrue: handleOpenQuest, setFalse: handleCloseQuest },
  ] = useBoolean(false);
  const { firstQuestFinished } = useApp({ handleOpenQuest });
  const { data: session, status } = useSession();
  const openedOnce = useRef(false);
  const loadingAuth = status === "loading";

  useEffect(() => {
    if (
      !firstQuestFinished &&
      firstQuestFinished !== null &&
      !openedOnce.current
    ) {
      handleOpenQuest();
      openedOnce.current = true;
    }
  }, [firstQuestFinished, handleOpenQuest, session]);

  return questOpen && !!session && !loadingAuth ? (
    <Suspense fallback={<></>}>
      <FirstQuest onClose={handleCloseQuest} />
    </Suspense>
  ) : (
    <>
      <Routes>
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/" element={<HomePage />} />
        <Route
          path="/dashboard"
          element={
            <RequireAuth>
              <Suspense fallback={<> </>}>
                <DashboardPage />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/achievements"
          element={
            <RequireAuth>
              <Suspense fallback={<> </>}>
                <AchievementsPage />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/goals"
          element={
            <RequireAuth>
              <Suspense fallback={<> </>}>
                <GoalsPage />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/goal/:id"
          element={
            <RequireAuth>
              <Suspense fallback={<> </>}>
                <GoalPage />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/todo/:id"
          element={
            <RequireAuth>
              <Suspense fallback={<> </>}>
                <TodoPage />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/timetracker"
          element={
            <RequireAuth>
              <Suspense fallback={<> </>}>
                <TrackedTimePage />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/profile"
          element={
            <RequireAuth>
              <Suspense fallback={<> </>}>
                <ProfilePage />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/focusing"
          element={
            <RequireAuth>
              <RequireSubscription
                info={
                  <div
                    style={{
                      padding: 16,
                      textAlign: "center",
                      margin: "0 auto",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        borderRadius: 32,
                        width: 64,
                        height: 64,
                        color: "#ff8a00",
                        background: "rgba(255, 138, 0, 0.1)",
                        margin: "0 auto",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <FocusIcon width={36} height={36} />
                    </div>
                    <Spacer y={1} />
                    <div style={{ fontSize: 20, fontWeight: 500 }}>
                      {t("PremiumFeatureFocusingTitle")}
                    </div>
                    <Spacer y={1} />
                    <div>{t("PremiumFeatureFocusingSubitle")}</div>
                    <Spacer y={2} />
                    <Link
                      to="/premium"
                      style={{
                        display: "block",
                        padding: "4px 8px",
                        background: "rgba(143,1,255,1)",
                        borderRadius: 8,
                        color: "#fff",
                      }}
                    >
                      {`${t("Available with")} ForgeSelf ${t("Premium")}`}
                    </Link>
                  </div>
                }
              >
                <Suspense fallback={<> </>}>
                  <FocusingPage />
                </Suspense>
              </RequireSubscription>
            </RequireAuth>
          }
        />
        <Route
          path="/premium"
          element={
            <RequireAuth>
              <Suspense fallback={<> </>}>
                <SubscriptionPage />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/dash"
          element={
            <RequireAuth>
              <Suspense fallback={<> </>}>
                <DashPage />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/policy"
          element={
            <Suspense fallback={<> </>}>
              <PolicyPage />
            </Suspense>
          }
        />
        <Route
          path="/pricing"
          element={
            <Suspense fallback={<> </>}>
              <PricingPage />
            </Suspense>
          }
        />
        <Route
          path="/support"
          element={
            <Suspense fallback={<> </>}>
              <SupportPage />
            </Suspense>
          }
        />
        <Route
          path="/auth/signin"
          element={
            <Suspense fallback={<> </>}>
              <AuthSignInPage />
            </Suspense>
          }
        />
        <Route
          path="/goals-achieving-problem"
          element={
            <Suspense fallback={<> </>}>
              <GoalsProblemPage />
            </Suspense>
          }
        />
        <Route
          path="/planning-things-problem"
          element={
            <Suspense fallback={<> </>}>
              <PlanningProblemPage />
            </Suspense>
          }
        />
        <Route
          path="/flexibility-solves"
          element={
            <Suspense fallback={<> </>}>
              <FlexibilitySolvesPage />
            </Suspense>
          }
        />
        <Route
          path="/positive-motivation"
          element={
            <Suspense fallback={<> </>}>
              <PositiveMotivationPage />
            </Suspense>
          }
        />
        <Route
          path="/personalization"
          element={
            <Suspense fallback={<> </>}>
              <PersonalizationPage />
            </Suspense>
          }
        />
        <Route
          path="/time-saving"
          element={
            <Suspense fallback={<> </>}>
              <TimeSavingPage />
            </Suspense>
          }
        />
        <Route
          path="/use-cases/goal-tracker"
          element={
            <Suspense fallback={<> </>}>
              <GoalTrackerPage />
            </Suspense>
          }
        />
        <Route
          path="/use-cases/daily-planner"
          element={
            <Suspense fallback={<> </>}>
              <DailyPlannerPage />
            </Suspense>
          }
        />
        <Route
          path="/use-cases/time-tracker"
          element={
            <Suspense fallback={<> </>}>
              <TimeTrackerPage />
            </Suspense>
          }
        />
      </Routes>
    </>
  );
};

export default App;
