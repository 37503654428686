import React from "react";

const FocusIcon = ({
  width = 24,
  height = 24,
  style = {},
  variant = "outline",
  className,
}: {
  width?: number;
  height?: number;
  style?: React.CSSProperties;
  variant?: "outline" | "solid";
  className?: string;
}) =>
  variant !== "outline" ? (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      style={style as React.CSSProperties}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1818 4.19385C10.9894 2.79345 13.0102 2.79345 13.8178 4.19385L21.3538 17.2439C22.1614 18.6443 21.1498 20.3939 19.5346 20.3939H4.46385C2.84745 20.3939 1.83705 18.6443 2.64585 17.2439L10.1818 4.19385Z"
        fill="currentColor"
      />
    </svg>
  ) : (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      style={style as React.CSSProperties}
      className={className}
    >
      <path
        d="M13.0824 4.375L20.8766 17.875C21.3577 18.7083 20.7563 19.75 19.7941 19.75H4.20562C3.24337 19.75 2.64196 18.7083 3.12309 17.875L10.9173 4.375C11.3984 3.54167 12.6013 3.54167 13.0824 4.375Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );

export default FocusIcon;
