import React from "react";
import styles from "./Button.module.scss";
import ArrowPathIcon from "../Icons/ArrowPathIcon";

export interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  size?: "small" | "default" | "large";
  variant?: "default" | "text" | "outlined";
  color?: "default" | "primary" | "secondary" | "danger" | "white";
  children?: React.ReactNode;
  loading?: boolean;
  icon?: React.ReactNode;
  block?: boolean;
  v2?: boolean;
  slim?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      children,
      size,
      variant,
      loading,
      color,
      icon,
      block,
      v2,
      slim = false,
      ...rest
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        className={`${styles.button} ${
          styles[`button--variant-${variant}`] ?? ""
        } ${styles[`button--color-${color}`] ?? ""} ${
          styles[`button--size-${size}`] ?? ""
        } ${block ? styles[`button--block`] : ""} ${
          v2 ? styles[`button--v2`] : ""
        } ${v2 && children ? styles[`button--v2--text`] : ""} ${
          v2 && icon ? styles[`button--v2--icon`] : ""
        } ${slim ? styles[`button--slim`] : ""} ${className ?? ""}`}
        {...rest}
      >
        {loading && (
          <span className={styles.button__icon}>
            <ArrowPathIcon width={16} height={16} />
          </span>
        )}
        {icon && (
          <span
            className={children ? styles.button__icon : styles.button__soloIcon}
          >
            {icon}
          </span>
        )}
        {children}
      </button>
    );
  }
);

export default Button;
