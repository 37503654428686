import React from "react";

const CloudArrowDownIcon = ({
  width = 24,
  height = 24,
  style = {},
  className,
}: {
  width?: number;
  height?: number;
  style?: React.CSSProperties;
  variant?: "outline" | "solid";
  className?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    width={width}
    height={height}
    style={style as React.CSSProperties}
    className={className}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 9.75v6.75m0 0-3-3m3 3 3-3m-8.25 6a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z"
    />
  </svg>
);

export default CloudArrowDownIcon;
