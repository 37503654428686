import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { Capacitor } from "@capacitor/core";
import { authenticationService, nativeStorage, T_V, webStorage } from "./auth";

const AUTH_ERROR = "You need to be logged in to perform an action";

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/api/graphql`, // ${process.env.NEXTAUTH_URL}
});

const storage = Capacitor.isNativePlatform() ? nativeStorage : webStorage;

const authLink = setContext((_, { headers }) => {
  return new Promise(async (success, fail) => {
    // get the authentication token from storage if it exists
    const token = await storage.getRaw(T_V);
    // return the headers to the context so httpLink can read them
    success({
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    });
  });
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (AUTH_ERROR === message) {
        authenticationService.signOut();
      }
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const context = operation.getContext();
    const refreshedHeader = context.response.headers.get("fs-refreshed");
    const expiresHeader = context.response.headers.get("fs-expires");

    if (refreshedHeader && expiresHeader && authenticationService.currentUserValue) {
      authenticationService.refreshIn(refreshedHeader, {
        user: authenticationService.currentUserValue.user,
        expires: expiresHeader,
      })
    }

    return response;
  });
});

// You need to be logged in to perform an action

const apolloClient = new ApolloClient({
  ssrMode: typeof window === "undefined",
  link: authLink.concat(errorLink).concat(afterwareLink).concat(httpLink),
  cache: new InMemoryCache({
    resultCaching: false,
  }),
});

export default apolloClient;
