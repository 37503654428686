import React, { useMemo } from "react";

const Spacer = ({
  x,
  y,
  grow,
  shrink,
  inline = false,
  children,
  className,
}: {
  x?: number;
  y?: number;
  grow?: number;
  shrink?: number;
  inline?: boolean;
  children?: React.ReactNode;
  className?: string;
}) => {
  const style = useMemo(
    () =>
      ({
        width: typeof x === "number" ? x * 8 + "px" : x || "1px",
        height: typeof y === "number" ? y * 8 + "px" : y || "1px",
        flexGrow: grow,
        flexShrink: shrink,
        userSelect: "none",
        display: inline ? 'inline-block' : undefined,
      } as React.CSSProperties),
    []
  );

  return <div style={style} className={className}>{children}</div>;
};

export default Spacer;
