import { Helmet } from "react-helmet-async";
// import { Capacitor } from "@capacitor/core";
import Layout from "@components/Layout/Layout";
import Landing from "@components/Landing/Landing";
import { Navigate } from "react-router-dom";
import { useSession } from "@lib/auth";
import { useTranslation } from "react-i18next";

const HomePage: React.FC = () => {
  const { t, i18n } = useTranslation("common");
  const { data: session, status } = useSession();
  const loadingAuth = status === "loading";
  if (typeof window !== "undefined" && loadingAuth) return null;

  if (session) {
    // Capacitor.isNativePlatform() &&
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <Layout noWrap>
      <Helmet>
        <title>{`ForgeSelf – ${t(
          "Landing heading"
        ).toLocaleLowerCase()}`}</title>
        <meta
          property="og:title"
          content={`ForgeSelf – ${t("Landing heading").toLocaleLowerCase()}`}
          key="title"
        />
        <meta
          name="description"
          content={t("Landing subtitle")}
          key="description"
        />
        <meta
          property="og:description"
          content={t("Landing subtitle")}
          key="og:description"
        />
        <meta
          property="og:image"
          content={`${window.location.origin}/images/jpg/${i18n.language}/todos/desktop_todos_${i18n.language}_light.jpg`}
          key="image"
        />
      </Helmet>
      <Landing />
    </Layout>
  );
};

export default HomePage;
