import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { gql, useQuery } from "@apollo/client";
import { Prisma } from "@prisma/client";

import { SettingType, SubscriptionState } from "../../types";
import { QUEST_RISE_AND_SHINE } from "../../constants";

type UserSettingDetailed = Prisma.UserSettingGetPayload<{
  include: { setting: true };
}>;
type UserQuestDetailed = Prisma.UserQuestGetPayload<{
  include: { quest: true; goal: true };
}>;
const FS_APP_SETTINGS = "FS_APP_SETTINGS";
const FS_APP_THEME = "FS_APP_THEME";

export interface AppSettings {
  SETTING_UI_CUSTOM_PROGRESS: boolean;
  SETTING_UI_DASHBOARD_TAB: "day" | "week" | "month" | "pending";
  SETTING_UI_GOALS_TAB: string;
}

const parseSetting = (input: string, type: SettingType) => {
  if (type === SettingType.Boolean) return input === "true";
  if (type === SettingType.String) return input;
  return input;
};

export interface AppStorage {
  settings: AppSettings;
  quests: UserQuestDetailed[];
  firstQuestFinished: boolean | null;
  subscriptionActive: boolean;
  theme: AppTheme;
  changeTheme: (newTheme: AppTheme) => void;

  handleOpenQuest?: () => void;
}
const localSettings = localStorage.getItem(FS_APP_SETTINGS);
const defaultSettings = localSettings
  ? (JSON.parse(localSettings) as AppSettings)
  : {
      SETTING_UI_CUSTOM_PROGRESS: false,
      SETTING_UI_DASHBOARD_TAB: "day" as const,
      SETTING_UI_GOALS_TAB: "all",
    };

export const AppContext = createContext<AppStorage>({
  settings: defaultSettings,
  quests: [],
  firstQuestFinished: null,
  subscriptionActive: false,
  theme: null,
  changeTheme: () => {},
});

export const MySettingsQuery = gql`
  query {
    userSettings {
      setting {
        id
        type
      }
      value
    }
    subscriptionState {
      isActive
    }
  }
`;

export const MyQuestsQuery = gql`
  query {
    userQuests {
      quest {
        id
        name
        steps
      }
      goal {
        id
      }
      startedAt
      step
      finishedAt
      finished
    }
  }
`;

const initialTheme = localStorage.getItem(FS_APP_THEME);

type AppTheme = "light" | "dark" | null;

interface AppProviderProps {
  children: ReactNode;
}

const AppProvider: FC<AppProviderProps> = ({ children }: AppProviderProps) => {
  const [theme, setTheme] = useState<AppTheme>(
    initialTheme === "null" ? null : (initialTheme as AppTheme)
  );
  const { data: settingsData, refetch: getSettings } = useQuery<{
    userSettings: UserSettingDetailed[];
    subscriptionState: SubscriptionState;
  }>(MySettingsQuery, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        "fs-session-check": "normal",
      },
    },
  });
  const { data: questsData, refetch: getQuests } = useQuery<{
    userQuests: UserQuestDetailed[];
  }>(MyQuestsQuery, {
    fetchPolicy: "network-only",
  });

  const appData: AppStorage = useMemo(() => {
    let quests: UserQuestDetailed[] = [];
    let firstQuestFinished: boolean | null = null;
    let settings: AppSettings = defaultSettings;
    if (settingsData && Array.isArray(settingsData.userSettings)) {
      const newSettings: any = {};
      settingsData.userSettings.forEach((item) => {
        newSettings[item.setting.id] = parseSetting(
          item.value,
          item.setting.type as SettingType
        );
      });
      settings = newSettings as AppSettings;
    }
    localStorage.setItem(FS_APP_SETTINGS, JSON.stringify(settings));
    if (questsData && Array.isArray(questsData.userQuests)) {
      const fq = questsData.userQuests.find(
        ({ quest }) => quest.id === QUEST_RISE_AND_SHINE
      );
      quests = questsData.userQuests;
      if (fq) {
        firstQuestFinished = fq.finished;
      }
    }
    return {
      settings,
      quests,
      firstQuestFinished,
      subscriptionActive: settingsData?.subscriptionState.isActive || false,
      theme,
      changeTheme: (newTheme: AppTheme) => {
        setTheme(newTheme);
        localStorage.setItem(FS_APP_THEME, `${newTheme}`);
      },
    };
  }, [settingsData, questsData, theme, setTheme]);

  useEffect(() => {
    getSettings();
    const listener = () => {
      if (document.visibilityState === "visible") {
        getSettings();
      }
    };
    window.addEventListener("visibilitychange", listener);

    return () => {
      window.removeEventListener("visibilitychange", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AppContext.Provider value={appData}>{children}</AppContext.Provider>;
};

interface UseAppProps {
  handleOpenQuest?: () => void;
}

export const useApp = (props?: UseAppProps): AppStorage => {
  const data = useContext(AppContext);
  if (props && props.handleOpenQuest)
    data.handleOpenQuest = props.handleOpenQuest;

  return data;
};

export const useSettings = (): AppSettings => {
  const { settings } = useContext(AppContext);

  return settings;
};

export default AppProvider;
