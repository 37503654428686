import React from "react";

const FSLogo = ({ width = 32, height = 32, style = {} }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <circle cx="32" cy="32" r="32" fill="url(#paint0_linear_2348_5526)" />
    <path
      d="M30.577 12.719C31.2835 11.7603 32.7166 11.7603 33.423 12.719L40.8407 22.7849C40.946 22.9278 41.0722 23.054 41.2151 23.1593L51.281 30.577C52.2397 31.2834 52.2397 32.7166 51.281 33.423L41.2151 40.8407C41.0722 40.946 40.946 41.0722 40.8407 41.2151L33.423 51.281C32.7166 52.2397 31.2835 52.2397 30.577 51.281L23.1593 41.2151C23.054 41.0722 22.9278 40.946 22.7849 40.8407L12.719 33.423C11.7603 32.7166 11.7603 31.2835 12.719 30.577L22.7849 23.1593C22.9278 23.054 23.054 22.9278 23.1593 22.7849L30.577 12.719Z"
      fill="url(#paint1_linear_2348_5526)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2348_5526"
        x1="32"
        y1="0"
        x2="32"
        y2="64"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F8F8" />
        <stop offset="1" stopColor="#E8E8E8" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2348_5526"
        x1="32"
        y1="10.788"
        x2="32"
        y2="53.2121"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC700" />
        <stop offset="1" stopColor="#FF8A00" />
      </linearGradient>
    </defs>
  </svg>
);

export default FSLogo;
