import { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import NotFound from "@components/NotFound/NotFound";

const NotFoundPage: React.FC = () => {
  const { t } = useTranslation("common");
  const pageTitle = useMemo(
    () => `404 ${t("Page not found")} - ForgeSelf`,
    [t]
  );
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "auto",
      }}
    >
      <Helmet>
        <meta name="robots" content="noindex, follow" />
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} key="title" />
      </Helmet>
      <NotFound />
    </div>
  );
};

export default NotFoundPage;
