import "core-js/stable";
import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Capacitor } from "@capacitor/core";
import {
  StyleProvider,
  legacyLogicalPropertiesTransformer,
} from "@ant-design/cssinjs";
// import "antd/dist/reset.css";
import "./index.scss";
import App from "./components/App/App";
import reportWebVitals from "./reportWebVitals";
import { ApolloProvider } from "@apollo/client";
import apolloClient from "./lib/apollo";
import { SessionProvider } from "./components/SessionProvider";
import "./i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <SessionProvider>
    <ApolloProvider client={apolloClient}>
      <HelmetProvider>
        <StyleProvider
          hashPriority="high"
          transformers={[legacyLogicalPropertiesTransformer]}
        >
          <App />
        </StyleProvider>
      </HelmetProvider>
    </ApolloProvider>
  </SessionProvider>
  // </React.StrictMode>
);

if (!Capacitor.isNativePlatform() && process.env.NODE_ENV !== 'development') {
  // <!-- Yandex.Metrika counter -->
  (function (m: any, e: any, t: any, r: any, i: any, k?: any, a?: any) {
    m[i] =
      m[i] ||
      function () {
        (m[i].a = m[i].a || []).push(arguments);
      };
    m[i].l = 1 * (new Date() as unknown as number);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    (k = e.createElement(t)),
      (a = e.getElementsByTagName(t)[0]),
      (k.async = 1),
      (k.src = r),
      a.parentNode.insertBefore(k, a);
  })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

  (window as any)?.ym(88746673, "init", {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
  });
  // <!-- /Yandex.Metrika counter -->
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
