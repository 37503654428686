import Button from "@components/UI/Button/Button";
import { Result } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function NotFound() {
  const { t } = useTranslation("common");
  return (
    <>
      <Result
        icon={<div style={{ fontSize: 64 }}>4🤔4</div>}
        title={t("Page not found")}
        subTitle={t("Page not found details")}
        extra={
          <Link to="/">
            <Button color="primary">{t("BackToHome")}</Button>
          </Link>
        }
      />
    </>
  );
}
export default NotFound;
