import { User } from "@prisma/client";
import { useContext } from "react";
import { BehaviorSubject } from "rxjs";
import { Capacitor } from "@capacitor/core";
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";
import { SessionContext } from "@components/SessionProvider";

const apiUrl = process.env.REACT_APP_API_URL;

const handleResponse = (response: Response): Promise<Record<string, any>> => {
  return new Promise(async (r, rej) => {
    if (!response.ok) {
      rej("default"); // `wrong status: ${response.status}`
    } else {
      let json = await response.json();
      if (json.error) rej(json.error);
      r(json as Record<string, any>);
    }
  });
};
export const TOKEN_KEY = "currentUser";
export const T_V = "_t";

export const webStorage = {
  get: async (
    key: string
  ): Promise<{
    user: User;
    expires: string;
  } | null> => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  },
  getRaw: async (key: string): Promise<string | null> => {
    return localStorage.getItem(key);
  },
  set: async (key: string, value: string) => {
    localStorage.setItem(key, value);
  },
  remove: async (key: string) => {
    localStorage.removeItem(key);
  },
};
export const nativeStorage = {
  get: async (
    key: string
  ): Promise<{
    user: User;
    expires: string;
  } | null> => {
    try {
      const { value } = await SecureStoragePlugin.get({ key });
      return value ? JSON.parse(value) : null;
    } catch (e) {
      return null;
    }
  },
  getRaw: async (key: string): Promise<string | null> => {
    try {
      const { value } = await SecureStoragePlugin.get({ key });
      return value ?? null;
    } catch (e) {
      return null;
    }
  },
  set: async (key: string, value: string) => {
    try {
      await SecureStoragePlugin.set({ key, value });
    } catch (e) {
      // oops
    }
  },
  remove: async (key: string) => {
    try {
      await SecureStoragePlugin.remove({ key });
    } catch (e) {
      // oops
    }
  },
};

const storage = Capacitor.isNativePlatform() ? nativeStorage : webStorage;

// storage.set('abc', '123');

const currentUserSubject = new BehaviorSubject<{
  user: User;
  expires: string;
} | null>(null);
storage.get(TOKEN_KEY).then((v) => {
  currentUserSubject.next(v);
});

function signIn(email: string, code?: string, language?: string) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, code, language }),
  };

  return fetch(
    code ? `${apiUrl}/api/authorize` : `${apiUrl}/api/authenticate`,
    requestOptions
  )
    .then(handleResponse)
    .then(async (data) => {
      if ("session" in data) {
        const { session, jwt, isNewUser } = data;
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        await storage.set(T_V, jwt);
        await storage.set(TOKEN_KEY, JSON.stringify(session));
        currentUserSubject.next(session as { user: User; expires: string });

        return {
          session: session as { user: User; expires: string },
          isNewUser,
        };
      } else {
        return { codeSent: true };
      }
    });
}

async function refreshIn(
  jwt: string,
  session: { user: User; expires: string }
) {
  await storage.set(T_V, jwt);
  await storage.set(TOKEN_KEY, JSON.stringify(session));
  currentUserSubject.next(session as { user: User; expires: string });
}

const signOut = async () => {
  // remove user from local storage to log user out
  await storage.remove(TOKEN_KEY);
  await storage.remove(T_V);
  // await localStorage.removeItem(TOKEN_KEY);
  // await localStorage.removeItem(T_V);
  currentUserSubject.next(null);
};

export const authenticationService = {
  signIn,
  signOut,
  refreshIn,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};

export const useSession = () => {
  const session = useContext(SessionContext);
  return session;
};

export interface Session {
  data: {
    user: User;
    expires: string;
  } | null;
  status: "ready" | "loading";
}

window.onfocus = function () {
  storage.get(TOKEN_KEY).then((v) => currentUserSubject.next(v));
};
