import { createContext, FC, ReactNode, useEffect, useState } from "react";
import { authenticationService, Session } from "../lib/auth";

export const SessionContext = createContext<Session>({
  data: null,
  status: "loading",
});

export const SessionProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [data, setData] = useState<Session>({
    data: null,
    status: "loading",
  });

  useEffect(() => {
    authenticationService.currentUser.subscribe((x) => {
      setData(
        x
          ? {
              data: x,
              status: "ready",
            }
          : {
              data: null,
              status: "ready",
            }
      );
    });
  }, []);
  return (
    <SessionContext.Provider value={data}>{children}</SessionContext.Provider>
  );
};
