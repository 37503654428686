import { Bill, Goal, Prisma, Result, Todo } from "@prisma/client";
import { SETTING_UI_CUSTOM_PROGRESS } from "./constants";

export enum SettingType {
  Boolean = "Boolean",
  String = "String",
}

export enum WeekdayEnum {
  "MO" = "MO",
  "TU" = "TU",
  "WE" = "WE",
  "TH" = "TH",
  "FR" = "FR",
  "SA" = "SA",
  "SU" = "SU",
}

export enum DayPartEnum {
  MORNING = "MORNING",
  AFTERNOON = "AFTERNOON",
  EVENING = "EVENING",
}

export type SettingId = typeof SETTING_UI_CUSTOM_PROGRESS;

export type ProductivityStats = {
  date: string;
  value: number;
  rImportance: number;
  rComplexity: number;
  rInterest: number;
  goals: Goal[];
  todos: Todo[];
};

export type CalendarStats = {
  goals: Goal[];
  todos: Todo[];
  userAchievements: Prisma.UsersOnAchievementsGetPayload<{
    include: { rank: true; achievement: true };
  }>[];
  results: Result[];
};

export enum TARIFF {
  EARLYBIRD = "EARLYBIRD", // бесплатно для ранних пользователей, автопродление или выдача сразу на год
  PREMIUM = "PREMIUM", // будущая обычная подписка за 299р/4.99$
}

export type SubscriptionState = {
  isActive: boolean;
  tariff: TARIFF;
  nextBillDate?: Date;
  autoPaymentEnabled: boolean;
  latestBill?: Bill;
  checkoutUrl?: string;
};
